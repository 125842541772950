import { HttpClient } from "@angular/common/http";
import { resolve } from "@angular/compiler-cli/src/ngtsc/file_system";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable,of } from "rxjs";
import Products from "./product";
declare var alertify: any;

@Injectable({
    providedIn:'root'
})
export class ProductService{
    constructor(private http:HttpClient,private angularFirestore: AngularFirestore){}
    url:string="";

    getProducts(){
           return  this.angularFirestore.collection("Product").snapshotChanges();
    }
    createProduct (product:any){
        return this.angularFirestore.collection('Product').add(product).then(()=>{
            alertify.error('Ürün Başarıyla Eklendi')
        });
    }
    updateProduct(product:any){
        this.url=`Product/${product.id}`
        delete product.id;
        this.angularFirestore.doc(this.url).update(product.newproductModel).then(()=>{
            alertify.error('Ürün Başarıyla Güncellendi')
        });
    }
    deleteProduct(productId: string){
        this.angularFirestore.doc('Product/' + productId).delete().then((resp)=>{
            alertify.error('Ürün Başarıyla Silindi')
        });
    }
}